<template>
    <!-- <OrdersTabs activeTab="requested" /> -->
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Requested Orders</h1>
                <!-- <div>A list of orders that have been accepted</div> -->
            </div>
            <div>
                <!-- <router-link class="btn btn-primary" to="/orders/create">Create Order</router-link> -->
            </div>
        </div>
        <ul v-if="orderData && orderData.data.length" class="order-list mb-4">
            <li v-for="order of orderData.data" v-bind:key="order.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class=""><strong>{{ order.patient_name }}</strong></div>
                        <div class="">{{ order.dentist.first_name }} {{ order.dentist.surname }}</div>
                    </div>
                    <div class="col flex-grow-0 flex-sm-grow-1 d-flex justify-content-between align-items-center">
                        <div class="d-none d-md-block text-center">
                            <div class="" v-if="order.standard">
                                {{ order.standard.description }}
                            </div>
                        </div>
                        <div class="d-none d-sm-block text-center">
                            <span class="badge" :class="statusClass(order.is_delayed_forever)">{{ getStatusText(order.is_delayed_forever) }}</span>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-end gap-2">
                        <div class="" v-if="order.has_barcode">
                            <router-link class="btn btn-secondary view-button" :to="{ name: 'OrderCreateSend', params: { id: order.temp_order_id }}">View</router-link>
                        </div>
                        <div>
                            <router-link class="btn btn-secondary update-button" :to="{ name: 'OrderUpdate', params: { id: order.temp_order_id }}">Update</router-link>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="orderData && !orderData.data.length">
            No Orders
        </div>
        <Pagination
            v-if="orderData && orderData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="PendingOrders"
        />
    </div>
</template>

<style scoped lang="scss">
    .order-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }
    }

</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';
    // import OrdersTabs from '@/components/OrdersTabs.vue';
    export default {
        components: {            
            Pagination,
            // OrdersTabs,
        },
        data() {
            return {
                orderData: null,
            }
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.orderData !== null)
                {
                    return parseInt(this.orderData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            fetchOrders: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.orderData = null;
                    return;
                }

                this.moveUp();
                
                api
                .get('/api/v1/pending-orders', {
                    params: {
                        practice_id: this.$store.state.currentPracticeId,
                        page: this.currentPage,
                    }
                })
                .then((response) => {
                    this.orderData = response.data;
                })
                .catch(() => {
                    console.log('Catch orders error');
                });
            },
            statusClass: function(status) {
                if (status)
                {
                    return 'bg-secondary';
                }
                return 'bg-success';
            },
            getStatusText: function(status) {
                if (status)
                {
                    return 'Draft';
                }
                return 'Pending';
            }
        },
        mounted () {
            this.fetchOrders();
        },
        watch: {
            currentPage() {
                this.fetchOrders();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchOrders();
            }
        },
    }

</script>
